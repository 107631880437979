import React from 'react'
import './CardDestaque.css'
export default function CardDestaque(props) {
  return (
    <div className='carte-destaque'>
        {props.children}
      
    </div>
  )
}
