import React from 'react';
import './sidebarProfile.css'
import { MenuData } from './MenuData';
import favicon from './../../assets/images/logo_rouge.png'
import { useNavigate } from 'react-router-dom'
import People from './../../pages/Admin/img/people.png'



function SidebarProfile({nomeUser,handleLogout}){
     
    return(
      <div className=" sidebar-profile ">
          <div className='menu-sidebar-profile'>
       <div className='profile'>
        <img src={People} alt="people" />
        <span>{nomeUser}</span>
        </div>
          
                  <div class= "menu-profile">
                    <a  href={"../perfil/"+ localStorage.getItem('idUser')} className ="nav-profils">
                    <i className="fa-solid fa-user"></i>
                    <span>Seu Perfil</span>
                      </a>
                      <a  href={"../oficinas/"} className ="nav-profils">
                    
                    <i className="fa-solid fa-bookmark"></i>
                    <span>Salvos</span>
                      </a>
                      <a  href={"../perfil/"+ localStorage.getItem('idUser')} className ="nav-profils">
                    
                    <i className="fa-solid fa-clock-rotate-left"></i>
                    <span>Historico</span>
                      </a>
                      <a  href={"../perfil/"+ localStorage.getItem('idUser')} className ="nav-profils">
                    
                    <i className="fa-solid fa-upload"></i>
                    <span>Enviados</span>
                      </a>
                      <a  href={"../perfil/"+ localStorage.getItem('idUser')} className ="nav-profils">
                    
                    <i className="fa-solid fa-gear"></i>
                    <span>Configurações</span>
                      </a>
                      <a  href={"../perfil/"+ localStorage.getItem('idUser')} className ="nav-profils">
                    
                    <i className="fa-solid fa-question"></i>
                    <span>Central de ajuda</span>
                      </a>
                      <div onClick={handleLogout}><i className="fa-solid fa-right-from-bracket"></i>
                    <span>Sair</span></div>                    
                    
                     
                      </div>
                      
                
              
           
         

          </div>
          
          
          


        </div>
        

    
      
    )
}
export default SidebarProfile;

