import WooCommerceRestApi from "@woocommerce/woocommerce-rest-api";
//import moment from "moment";

export const api = new WooCommerceRestApi({
    url: "https://congolinaria.com.br",
  
  withCredentials: true,
    consumerKey: "ck_e2141191161da369339318ab3f6bdbfb9a712cac",
    consumerSecret: "cs_6b044b30c7d00964640c9a2ded81613b98d5de47",
    version: "wc/v3"
  });