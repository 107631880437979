import styled from "styled-components";

export const ButtonSelect = styled.button`
height: 6.4rem;
background:#f5f8fa;
border: 1px solid #d3e2e5;
color: #5c8599;
cursor: pointer;
padding : 15px;
:hover{
    background-color:#17d6eb;
    color:#fff;}
    
`